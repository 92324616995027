/* eslint-disable max-lines */
/* eslint-disable no-sparse-arrays */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import { SWITCH_ITEMS_TYPE } from '@scandipwa/scandipwa/src/component/Router/Router.config';
import UrlRewrites from '@scandipwa/scandipwa/src/route/UrlRewrites';
import {
    Suspense
} from 'react';
import { Route } from 'react-router-dom';

import Loader from 'Component/Loader/Loader';
import {
    Breadcrumbs,
    CartPage,
    Checkout,
    CmsPage,
    ConfirmAccountPage,
    ContactPage,
    CookiePopup,
    CreateAccountPage,
    DemoNotice,
    Footer,
    ForgotPasswordPage,
    Header,
    HomePage,
    LoginAccountPage,
    MenuPage,
    MyAccount,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    OrderPrintPage,
    PasswordChangePage,
    ProductComparePage,
    Router as SourceRouter,
    SearchPage,
    SendConfirmationPage,
    SomethingWentWrong,
    StyleGuidePage,
    WishlistShared,
    withStoreRegex
} from 'SourceComponent/Router/Router.component';

import { PrivateRoute } from './protectedRoute';

export {
    CartPage,
    Checkout,
    CmsPage,
    CookiePopup,
    DemoNotice,
    Header,
    HomePage,
    MyAccount,
    PasswordChangePage,
    SearchPage,
    SendConfirmationPage,
    ConfirmAccountPage,
    MenuPage,
    Footer,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    WishlistShared,
    OfflineNotice,
    ContactPage,
    ProductComparePage,
    CreateAccountPage,
    LoginAccountPage,
    ForgotPasswordPage,
    SomethingWentWrong,
    StyleGuidePage,
    Breadcrumbs,
    OrderPrintPage,
    withStoreRegex
};

/** @namespace ScandiDemo/Component/Router/Component */
export class RouterComponent extends SourceRouter {
  // TODO implement logic
  [SWITCH_ITEMS_TYPE] = [
      {
          component: (
        <PrivateRoute path={ withStoreRegex('/') } exact component={ HomePage } />
          ),
          position: 10
      },
      {
          component: (
        <PrivateRoute
          path={ withStoreRegex('/search/:query/') }
          // render={ (props) => <SearchPage { ...props } /> }
          component={ SearchPage }
        />
          ),
          position: 25
      },
      {
          component: (
        <PrivateRoute
          path={ withStoreRegex('/page') }
          // render={ (props) => <CmsPage { ...props } /> }
          component={ CmsPage }
        />
          ),
          position: 40
      },
      {
          component: (
        <PrivateRoute
          path={ withStoreRegex('/cart') }
          exact
          // render={ (props) => <CartPage { ...props } /> }
          component={ CartPage }
        />
          ),
          position: 50
      },
      {
          component: (
        <PrivateRoute
          path={ withStoreRegex('/checkout/:step?') }
          // render={ (props) => <Checkout { ...props } /> }
          component={ Checkout }
        />
          ),
          position: 55
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/customer/account/createPassword/') }
          render={ (props) => <PasswordChangePage { ...props } /> }
        />
          ),
          position: 60
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/customer/account/create/') }
          render={ (props) => <CreateAccountPage { ...props } /> }
        />
          ),
          position: 61
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/customer/account/login/') }
          render={ (props) => <LoginAccountPage { ...props } /> }
        />
          ),
          position: 62
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/customer/account/forgotpassword/') }
          render={ (props) => <ForgotPasswordPage { ...props } /> }
        />
          ),
          position: 63
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/customer/account/confirmation') }
          render={ (props) => <SendConfirmationPage { ...props } /> }
        />
          ),
          position: 64
      },
      {
          component: (
        <Route
          path={ withStoreRegex('/customer/account/confirm') }
          render={ (props) => <ConfirmAccountPage { ...props } /> }
        />
          ),
          position: 65
      },
      {
          component: (
        <PrivateRoute
          path={ withStoreRegex('/sales/order/view/order_id/:orderId?') }
          // render={ (props) => <MyAccount { ...props } /> }
          component={ MyAccount }
        />
          ),
          position: 70
      },
      {
          component: (
        <PrivateRoute
          path={ withStoreRegex('/sales/order/history') }
          // render={ (props) => <MyAccount { ...props } /> }
          component={ MyAccount }
        />
          ),
          position: 71
      },
      {
          component: (
        <PrivateRoute
          path={ withStoreRegex('/downloadable/customer/products') }
          // render={ (props) => <MyAccount { ...props } /> }
          component={ MyAccount }
        />
          ),
          position: 72
      },
      {
          component: (
        <PrivateRoute
          path={ withStoreRegex('/wishlist') }
          // render={ (props) => <MyAccount { ...props } /> }
          component={ MyAccount }
        />
          ),
          position: 73
      },
      {
          component: (
        <PrivateRoute
          path={ withStoreRegex('/customer/address') }
          // render={ (props) => <MyAccount { ...props } /> }
          component={ MyAccount }
        />
          ),
          position: 75
      },
      {
          component: (
        <PrivateRoute
          path={ withStoreRegex('/newsletter/manage') }
          // render={ (props) => <MyAccount { ...props } /> }
          component={ MyAccount }
        />
          ),
          position: 76
      },
      {
          component: (
        <PrivateRoute
          path={ withStoreRegex('/customer/account/:tab?') }
          // render={ (props) => <MyAccount { ...props } /> }
          component={ MyAccount }
        />
          ),
          position: 77
      },
      {
          component: (
        <PrivateRoute
          path={ withStoreRegex('/menu') }
          // render={ (props) => <MenuPage { ...props } /> }
          component={ MenuPage }
        />
          ),
          position: 80
      },
      {
          component: (
        <PrivateRoute
          path={ withStoreRegex('/wishlist/shared/:code') }
          // render={ (props) => <WishlistShared { ...props } /> }
          component={ WishlistShared }
        />
          ),
          position: 81
      },
      {
          component: (
        <PrivateRoute
          path={ withStoreRegex('/contact') }
          // render={ (props) => <ContactPage { ...props } /> }
          component={ ContactPage }
        />
          ),
          position: 82
      },
      {
          component: (
        <PrivateRoute
          path={ withStoreRegex('/compare') }
          // render={ (props) => <ProductComparePage { ...props } /> }
          component={ ProductComparePage }
        />
          ),
          position: 83
      },
      {
          component: (
        <PrivateRoute
          path={ withStoreRegex('/styleguide') }
          // render={ (props) => <StyleGuidePage { ...props } /> }
          component={ StyleGuidePage }
        />
          ),
          position: 84
      },
      {
          component: (
        <PrivateRoute
          path={ withStoreRegex('/sales/order/print/order_id/:orderId?') }
          // render={ (props) => <OrderPrintPage { ...props } /> }
          component={ OrderPrintPage }
        />
          ),
          position: 90
      },
      {
          component: (
        <PrivateRoute
          path={ withStoreRegex('/sales/order/printInvoice/order_id/:orderId?') }
          // render={ (props) => <OrderPrintPage { ...props } /> }
          component={ OrderPrintPage }
        />
          ),
          position: 91
      },
      {
          component: (
        <PrivateRoute
          path={ withStoreRegex('/sales/order/printShipment/order_id/:orderId?') }
          // render={ (props) => <OrderPrintPage { ...props } /> }
          component={ OrderPrintPage }
        />
          ),
          position: 92
      },
      {
          component: (
        <PrivateRoute
          path={ withStoreRegex(
              '/sales/order/printCreditmemo/order_id/:orderId?'
          ) }
          // render={ (props) => <OrderPrintPage { ...props } /> }
          component={ OrderPrintPage }
        />
          ),
          position: 93
      },
      {
          component: (
        <PrivateRoute
          path={ withStoreRegex(
              '/sales/order/printInvoice/invoice_id/:invoiceId?'
          ) }
          // render={ (props) => <OrderPrintPage { ...props } /> }
          component={ OrderPrintPage }
        />
          ),
          position: 94
      },
      {
          component: (
        <PrivateRoute
          path={ withStoreRegex(
              '/sales/order/printShipment/shipment_id/:shipmentId?'
          ) }
          // render={ (props) => <OrderPrintPage { ...props } /> }
          component={ OrderPrintPage }
        />
          ),
          position: 95
      },
      {
          component: (
        <PrivateRoute
          path={ withStoreRegex(
              '/sales/order/printCreditmemo/creditmemo_id/:refundId?'
          ) }
          // render={ (props) => <OrderPrintPage { ...props } /> }
          component={ OrderPrintPage }
        />
          ),
          position: 95
      },
      {
          component: <PrivateRoute component={ UrlRewrites } />,
          position: 1000
      }
      // [...]
  ];

  renderSectionOfType(type) {
      return (
        <Suspense fallback={ <Loader isLoading /> }>
            { this.renderComponentsOfType(type) }
        </Suspense>
      );
  }

  renderFallbackPage() {
      return (
        <main block="Router" elem="Loader">
            <Loader isLoading />
        </main>
      );
  }
}

export default RouterComponent;
